.wjmm {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.26rem;
}
.wjmm span {
  font-size: 0.24rem;
  color: #999999;
}
.login-dex1 {
  margin-top: 0.55rem;
}

.loginButtonQ {
  width: 100%;
  border-radius: .1rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #E0C3FC 0%, #8EC5FC 100%);
  line-height: 0.88rem;
  border: none;
  margin-top: 0.7rem;
  // margin-bottom: 1rem !important;
  font-size: 0.32rem;
  // font-family: PingFang SC;
  // font-weight: bold;
  color: #999999;
  font-weight: 600;
}
.loginButtonQ.disabled {
	background: #E9E9E9 !important;
}


.c-reg-box {

  overflow: hidden;
  background: #ffffff;
  font-size: 0.38rem;
  color: #ffffff;
  // background-size: cover;


  .login-logo {
    width: 100%;
    height: 3.6rem;
    margin-top: 1rem;
    position: relative;

    .logo-shallde {
      width: 2rem;
      height: 2rem;
      box-shadow: 0rem 0.1rem 0.2rem rgb(243, 240, 240);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;

      img {
        width: 1.4rem;
        height: 1.4rem;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.7rem;
        margin-top: -0.7rem;

      }
    }


  }

  .c-reg-nav {
    width: 4.3rem;
    margin-left: 0.6rem;
    margin-top: 0.8rem;
    display: flex;
    box-sizing: border-box;

    & > div {
      flex: 1;
      text-align: center;

      & > a {
        color: #b1a9a9;
        display: block;
        cursor: pointer;
        padding: .1rem 0;
        position: relative;

        &.active {
          color: #1e1010;
          font-weight: 600;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 80%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }


  .c-reg-btn {
    display: block;
    border: none;
    width: 80%;
    height: 1rem;
    color: #b1a9a9;
    border-radius: 0.6rem;
    margin: 0.4rem auto;
    font-size: 0.32rem;
    background-color: #e0dcdc;
  }

  .c-reg-btn2 {
    display: block;
    border: none;
    width: 80%;
    height: 1rem;
    color: #ffffff;
    border-radius: 0.6rem;
    margin: 0.4rem auto;
    font-size: 0.32rem;
    background-color: #ECBB34;
    // display: block;
    // border: none;
    // width: 40%;
    // height: 1rem;
    // color: #ffffff;
    // border-radius: 0.6rem;
    // margin: 1.8rem auto;
    // font-size: 0.32rem;
    // background-color: #ECBB34;
  }
}

.c-reg-panel {
  // background: #1e1010;
  // padding: 0.4rem 0.4rem 0;
  & > li:last-child{
    margin-bottom: 0;
  }
  & > li {
    display: flex;
    border-bottom: solid 0.01rem #D3D9DE;
    // margin-bottom: 0.5rem;
    margin-bottom: 0.25rem;

    // &:last-child {
    //     border-bottom: none;
    // }

    & > .c-reg-panel-leftbox {
      flex: 1;

      label {
        color: #cacaca;
        display: block;
        text-align: left;
        font-size: 0.28rem;
        margin-bottom: 0.1rem;
      }

      input {
        display: block;
        border: none;
        background: transparent;
        outline: none;
        height: 0.88rem;
        // margin-top: 0.2rem;
        line-height: 0.88rem;
        width: 100%;
        font-size: 0.28rem;
        font-weight: 500;
        color: #02172E;
		position: relative;
		z-index: 1;
      }

      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #999999;
      }

    }

    & > .c-reg-panel-rightbox {
      line-height: 0.8rem;
      min-width: 0.4rem;

      img {
        width: 0.4rem;
        height: 0.4rem;
      }

      .active {
        // display: block;
        border: none;
        height: 0.6rem;
        padding: 0 0.26rem;
        // width: 2rem;
        border-radius: 0.8rem;
        color: #ffffff;
        font-size: 0.24rem;
        line-height: 0.6rem;
        background-color: #ecbb34;

        span {
          color: #cacaca;
        }

      }

      .c-reg-code {
        // display: block;
        border: none;
        height: 0.6rem;
        padding: 0 0.26rem;
        // width: 2rem;
        border-radius: 0.8rem;
        color: #b1a9a9;
        font-size: 0.24rem;
        line-height: 0.6rem;
        background-color: #e0dcdc;

        span {
          color: #cacaca;
        }
      }
    }
  }
}

.c-reg-toreg {
  width: 100%;
  margin: 0.4rem auto 0;
  // text-align: right;

  span {
    color: #000000;
    font-size: 16px;
  }
}

.c-nabox {
  width: 5.6rem;
  margin: 1rem auto 0;
  text-align: right;

  span {
    color: #cacaca;
    font-size: 14px;
  }
}

.langTaggle {
  position: absolute;
  right: 0.1rem;
  color: #c3bdbd;
  width: 1.8rem;
  box-sizing: border-box;
  height: 0.46rem;
  display: flex;
  margin-top: 1.2rem;
  border: 0px;

  .lang-icon {
    width: 0.58rem;
    height: 0.48rem;
    margin-right: 2px;
  }

  /deep/ .van-field {
    border: 1px rgba(0, 0, 0, 0.7) solid;
    width: 0.8rem;
    padding: 0;
    height: 0.46rem;
    font-size: 0.26rem;

    .van-field__control {
      line-height: 0.46rem;
      text-align: center;
    }
  }
}

.flag-list-box {
  background: #ffffff;
  //    .country-name{
  //        color: #ffffff;
  //    }
}


/* ----20210409--- */
.posRelat {
	position: relative;
}
.getCodeBtn {
	position: absolute;
	z-index: 2;
	right: 0;
	bottom: .15rem;
}
.getCodeBtn button {
	color: #2E62FF;
	font-size: .24rem;
	// width: .96rem;
	height: .52rem;
	// border-radius: .26rem;
	border: none;
	background: none;
}
body {
	font-family: 'PingFangSC-Regular', 'PingFang SC', "微软雅黑", "Microsoft Yahei";
}









































































































































































@import url('../../assets/css/login.less');
.loginBox {
	background-color: #FFFFFF;
	height: 100vh;
	font-family: PingFangSC-Regular;
	.head {
		display: flex;
		margin: 0 0.72rem 0 0.72rem;
		padding-top: 0.28rem;
		align-items: center;
		justify-content: space-between;
		.langBtn {
			.icon {
				width: 0.4rem;
				height: 0.4rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					display: block;
					width: 100%;
				}
			}
		}
		.zhuce {
			.txt {
				text-align: center;
				line-height: 0.51rem;
				color: #5569FC;
				font-size: 0.32rem;
				font-weight: 600;
			}
		}
	}
	.loginBg {
		position: absolute;
		z-index: 0;
		left: 0;
		right: 0;
		top: -.8rem;
		height: 100vh;
		overflow: hidden;
		img {
			width: 100%;
			display: block;
		}
	}
	.loginCon {
		position: relative;
		z-index: 1;
	}
	.zhuceBox {
		text-align: center;
		/* position: fixed; */
		/* left: 0; */
		width: 100%;
		/* bottom: 0.5rem; */
		margin-top: 0.5rem;
		padding-bottom: 0.5rem;
		span {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: bold;
			color: #ff4b04;
		}
	}

	.loginCon .icon {
		// padding-top: 1.42rem;
		padding-top: 1.2rem;
		width: 2rem;
		height: 2rem;
		box-sizing: content-box;
		margin: auto;

		img {
			// width: 3.25rem;
			// width: 2rem;
			width: 100%;
			height: 100%;
			display: block;
			margin: auto;
		}
		p {
			color: white;
			font-size: .48rem;
			text-align: center;
		}
	}

	.login {
		// padding: 1rem 1rem 0 1rem;
		padding: 1.48rem 0.67rem 0 0.71rem;
		
		.login_txt {
			position: relative;
			// padding-top: 0.3rem;
			.l_txt {
				font-size: 0.48rem;
				font-weight: 700;
			}
			.yy {
				position: absolute;
				left: 0;
				bottom: 0.05rem;
				z-index: -1;
				background-color: #8A8EFE;
				opacity: 0.2;
				height: 0.15rem;
				width: 1rem;
			}
		}

		.loginTab {
			// width: 4.6rem;
			display: flex;
			align-items: center;
			// height: .70rem;
			// margin: 0 0 0.4rem 0;
			div {
				font-size: 0.32rem;
				// font-family: PingFang SC;
				// font-weight: bold;
				text-align: center;
				color: #999999;
				display: inline-block;
				// width: 1.76rem;
				line-height: .7rem;
				// margin: 0 .2rem;
				margin-right: 0.95rem;
				font-weight: 600;
				&:last-child{
					margin-right: 0rem;
				}
			}
			div:first-child {
				margin-right: 0.99rem;
			}

			div.on,
			div.mainColor1{
				color: #333333 !important;
				font-size: 0.44rem;
				font-weight: 600;
			}
		}
		
		.tips {
			color: #999999;
			font-size: .28rem;
			text-align: center;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;